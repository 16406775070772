* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: sans-serif;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background-color: #000;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 1;
}

section:nth-child(2n) {
  background-color: #fff;
}

.section {
  transition: all .5s;
}

.header {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 9.6rem;
  padding: 0 4.8rem;
  display: flex;
  position: relative;
}

.sticky.nav-container {
  z-index: 999;
  background-color: #fffffff5;
  width: 100%;
  height: 9.6rem;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

.btn-mobile-nav {
  display: none;
}

.grid-nav {
  grid-column: 1fr 1fr;
  display: grid;
}

.nav-container {
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  transition: all .3s;
  display: flex;
}

.nav-list-container {
  justify-content: space-between;
  align-items: center;
  gap: 6.4rem;
  padding: 2.4rem;
  display: flex;
}

.main-nav-list {
  font-size: 2.4rem;
  font-weight: 500;
  list-style: none;
}

.nav-icons {
  font-size: 2.4rem;
}

.logo-image {
  max-width: 24rem;
}

.btn-mobile-nav {
  cursor: pointer;
  background: none;
  border: none;
  display: none;
}

.icon-mobile-nav {
  color: #333;
  width: 4.8rem;
  height: 4.8rem;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.main-nav-link, .main-nav-link:link, .main-nav-link:visited {
  color: #000;
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.main-nav-link:hover, .main-nav-link:active {
  color: #4c4c4c;
}

.hero-container {
  max-width: 140rem;
  height: 85rem;
  margin: 0 auto;
  padding: 6.4rem;
  position: relative;
}

.hero-header-container {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  gap: 3.2rem;
  padding: 4.8rem;
  display: flex;
}

.business-name {
  color: gold;
  font-size: 1.6rem;
}

.hero-main-header {
  font-size: 5.2rem;
}

.hero-button-container {
  justify-content: space-evenly;
  padding: 4.8rem 0;
  display: flex;
}

.hero-button, .hero-button:link, .hero-button:visited {
  color: #fff;
  border: none;
  border-inline: .1rem solid #fff;
  border-radius: 2.4rem;
  padding: 1.6rem 3.2rem;
  font-size: 2.4rem;
  font-weight: 300;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.hero-button:hover, .hero-button:active {
  color: #000;
  background-color: #e6e6e6;
}

.hero-main-button, .hero-main-button:link, .hero-main-button:visited {
  color: #000;
  background-color: #fff;
  font-weight: 600;
}

.hero-main-button:hover, .hero-main-button:active {
  background-color: #e6e6e6;
}

.related-container {
  flex-direction: column;
  gap: 2.4rem;
  display: flex;
}

.hero-list-container {
  align-items: center;
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.hero-lists {
  flex-direction: column;
  gap: .8rem;
  max-width: 12rem;
  display: flex;
}

.related-image {
  border-radius: .5rem;
  width: 100%;
}

.related-text {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.hero-image-container {
  justify-content: center;
  align-items: center;
  width: 50rem;
  padding: 4.8rem;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero-image-list-container {
  list-style: none;
  top: 0;
  left: 0;
}

.hero-slider-images-container {
  object-fit: cover;
  max-width: 60rem;
  position: absolute;
  transform: translateX(100%);
}

.hero-slider-images--active {
  z-index: -2;
  transform: translateX(0);
}

.hero-image {
  border-radius: .5rem;
  width: 100%;
}

.hero-list-button, .hero-list-button:link, .hero-list-button:visited {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: none;
  list-style: none;
  transition: all .3s;
  display: inline-block;
}

.hero-list-button:hover, .hero-list-button:active {
  scale: 1.1;
}

.hero--active {
  z-index: -2;
  transition: all .3s;
  scale: 1.1;
}

.social-nav-container {
  z-index: 999;
  background-color: #fffffff2;
  border-radius: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  height: 16rem;
  padding: 2.4rem;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: 50%;
  right: 2.4rem;
  box-shadow: .1rem 0 1rem #000;
}

.biography {
  row-gap: 2.4rem;
  padding: 4.8rem;
}

.biography-text-container {
  gap: 2.2rem;
  display: flex;
}

.biography-text {
  color: #1a1a1a;
  justify-content: center;
  align-self: center;
  padding: 2.4rem;
  font-size: 2rem;
  line-height: 1.3;
}

.biography-image-container {
  background-color: #fff;
  place-self: center;
  max-width: 120rem;
  position: relative;
}

.biography-image {
  border-radius: .5rem;
  justify-content: center;
  align-self: center;
  max-width: 100%;
}

.gallery-main-container {
  row-gap: 9.6rem;
  padding-bottom: 4.8rem;
  display: grid;
}

.gallery-header {
  color: #fff;
  letter-spacing: .6rem;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 5rem;
  font-family: Cormorant Garamond, serif;
  font-size: 4.4rem;
  font-weight: 600;
  display: flex;
}

.gallery-image-container {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.2rem;
  margin: 0 auto;
  display: grid;
  overflow: hidden;
}

.gallery-image {
  max-width: 100%;
  transition: all .3s;
}

.gallery-image:hover {
  cursor: pointer;
  scale: 1.1;
}

.image-holder {
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.btn-gallery, .btn-gallery:link, .btn-gallery:visited {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #000;
  border: .1rem solid #fff;
  border-radius: .8rem;
  grid-column: 2;
  justify-self: center;
  padding: 1.8rem 3.6rem;
  font-size: 2.4rem;
  font-weight: 500;
  transition: all .3s;
  display: inline-block;
}

.btn-gallery:hover, .btn-gallery:active {
  color: #000;
  background-color: #fff;
}

.section-price {
  row-gap: 4.8rem;
}

.header-container-price {
  margin: 0 auto;
  padding: 4.8rem;
  display: inline-block;
}

.card-container {
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-self: center;
  column-gap: 4.8rem;
  display: grid;
}

.card-holder {
  border-top-left-radius: 4.8rem;
  border-bottom-right-radius: 4.8rem;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 48rem;
  padding: 2.4rem;
  display: grid;
  position: relative;
  overflow: hidden;
  box-shadow: 0 .1rem 1rem #333;
}

.ribbon {
  background-color: gold;
  justify-content: center;
  align-items: center;
  width: 24rem;
  max-height: .8rem;
  padding: 1.6rem 2.4rem;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 5%;
  right: -25%;
  transform: rotate(45deg);
}

.card-header {
  color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.2rem;
  display: flex;
}

.price {
  font-size: 2.4rem;
}

.price-pence {
  color: #333;
}

.price-symbol {
  font-size: 8rem;
}

.card-title {
  font-size: 3.2rem;
}

.description-holder {
  font-size: 1.8rem;
  line-height: 1.5;
  list-style: none;
}

.description-icon {
  color: green;
  padding-right: 1rem;
  font-size: 1.6rem;
}

.slider {
  border-radius: 2rem;
  justify-content: center;
  width: 80rem;
  height: 24rem;
  margin: 0 auto;
  padding: 4.8rem;
  line-height: 3.2rem;
  display: flex;
  position: relative;
}

.slide {
  opacity: 0;
  flex-direction: column;
  padding: 2.4rem 3.2rem;
  display: flex;
  position: absolute;
}

.slide-header {
  padding-bottom: 1.6rem;
  font-size: 3rem;
}

.slide-comment {
  color: #1a1a1a;
  padding: 1.6rem 0;
  font-size: 2rem;
  line-height: 3.2rem;
}

.quotes {
  color: gold;
  font-size: 5.2rem;
}

.author {
  text-align: end;
  color: #1a1a1a;
  font-size: 1.8rem;
}

.slider-button, .slider-button:link, .slider-button:visited {
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 4.8rem;
  height: 4.8rem;
  transition: all .3s;
  position: absolute;
  box-shadow: 0 .1rem 1.2rem #333;
}

.slider-button:hover, .slider-button:active {
  color: #fff;
  background-color: #000;
}

.slider-testimonial-icon {
  font-size: 4.8rem;
}

.slide--active {
  opacity: 1;
}

.button--next {
  top: 50%;
  right: 0;
  transform: translateX(100%);
}

.button--prev {
  top: 50%;
  left: 0;
  transform: translateX(-100%);
}

.cta-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  max-width: 144rem;
  padding: 4.8rem;
  display: flex;
}

.cta-header {
  text-align: center;
  color: #fff;
  padding: 4.8rem;
  font-size: 5.2rem;
  line-height: 1.8;
}

.cta-button, .cta-button:link, .cta-button:visited {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 2.4rem;
  padding: 3.2rem 6.4rem;
  font-size: 2.4rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
}

.cta-button:hover, .cta-button:active {
  color: #fff;
  background-color: #000;
  border-inline: .2rem solid #fff;
}

.cta-social-container {
  justify-content: space-between;
  align-items: center;
  width: 80rem;
  list-style: none;
  display: flex;
}

.cta-social-link, .cta-social-link:link, .cta-social-link:visited {
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  font-size: 4.8rem;
  transition: all .4s;
  display: flex;
}

.cta-social-link:hover, .cta-social-link:active {
  color: gold;
  background-color: #000;
  scale: 1.5;
}

.footer-section {
  border-top: .1rem solid #fff;
}

.footer-container {
  color: #e6e6e6;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 4.8rem;
  display: flex;
}

.copyright {
  text-align: center;
  font-size: 1.6rem;
}

.modal {
  z-index: 1000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #000c;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal img {
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  margin: 0 auto;
  padding: 4.8rem;
  display: flex;
}

.close-modal {
  color: #fff;
  cursor: pointer;
  font-size: 4.8rem;
  position: absolute;
  top: 1%;
  right: 1%;
}

/*# sourceMappingURL=index.5688aa07.css.map */
