/* 
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

Calculating rem
font-size: 10px;
10px / 16px = 0.625 = 62.5%



-- 02 Colors

Primary   #fff
secondary #000
tertiary  #4c4c4c / Gold

Tints:

-tints for Texts on white background
-#1a1a1a 

-tints for texts on black background
-#e6e6e6

// header and sub header font family//
font-family: 'Cormorant Garamond', serif;

// Font family for sub headers
font-family: 'Lobster', cursive;

*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  font-family: sans-serif;
}

body {
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-weight: 400;
  background-color: #000;
}

section:nth-child(even) {
  background-color: #fff;
}

.section {
  transition: all 0.5s;
}

.header {
  height: 9.6rem;
  padding: 0 4.8rem;

  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

/* STICKY NAVIGATION */
.sticky.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 9.6rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.96);
  z-index: 999;
  /* box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03); */
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

/* NAVIGATION */

.btn-mobile-nav {
  display: none;
}

.grid-nav {
  display: grid;
  grid-column: 1fr 1fr;
}

.nav-container {
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s;
  /* background-color: blue; */
}

.nav-list-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6.4rem;
  padding: 2.4rem;
}

.main-nav-list {
  font-size: 2.4rem;
  font-weight: 500;
  list-style: none;
}

.nav-icons {
  font-size: 2.4rem;
}

/* .logo-container {
} */

.logo-image {
  /* display: inline-block; */
  max-width: 24rem;
}

/* mobile button */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

/* Navigation Buttons */
.main-nav-link,
.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 500;
  color: #000;
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #4c4c4c;
}

/* Section-hero */

.hero-container {
  margin: 0 auto;
  max-width: 140rem;
  height: 85rem;
  padding: 6.4rem;
  position: relative;
}

/* hero texts */

.hero-header-container {
  padding: 4.8rem;

  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  justify-content: center;

  color: #fff;
}

.business-name {
  font-size: 1.6rem;
  color: gold;
}

.hero-main-header {
  font-size: 5.2rem;
}

/* hero button container */

.hero-button-container {
  display: flex;
  justify-content: space-evenly;
  padding: 4.8rem 0;
}

.hero-button,
.hero-button:link,
.hero-button:visited {
  display: inline-block;
  text-decoration: none;
  border: none;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 300;
  padding: 1.6rem 3.2rem;
  border-radius: 2.4rem;
  border-inline: 0.1rem solid #fff;
  transition: all 0.3s;
}

/* hero main button */
.hero-button:hover,
.hero-button:active {
  background-color: #e6e6e6;
  color: #000;
}

.hero-main-button,
.hero-main-button:link,
.hero-main-button:visited {
  font-weight: 600;
  background-color: #fff;
  color: #000;
}

.hero-main-button:hover,
.hero-main-button:active {
  background-color: #e6e6e6;
}

/* related hero section */

.related-container {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.hero-list-container {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.hero-lists {
  max-width: 12rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.related-image {
  width: 100%;
  border-radius: 0.5rem;
}

.related-text {
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
}

/* HERO IMAGES */

.hero-image-container {
  padding: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  width: 50rem;

  position: relative;
  overflow: hidden;
}

.hero-image-list-container {
  list-style: none;
  top: 0;
  left: 0;
}

.hero-slider-images-container {
  max-width: 60rem;
  position: absolute;
  transform: translateX(100%);
  object-fit: cover;
}
.hero-slider-images--active {
  transform: translateX(0);
  z-index: -2;
}

.hero-image {
  width: 100%;
  border-radius: 0.5rem;
}
/* 
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateX(100%);
  transition: all 0.3s;
   */

/* .hero-slider-images-container.hero-slider-active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  transform: translateX(-100);
   background-color: aqua; 
} */

.hero-list-button,
.hero-list-button:link,
.hero-list-button:visited {
  display: inline-block;
  border: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  list-style: none;
}

.hero-list-button:hover,
.hero-list-button:active {
  scale: 1.1;
}

.hero--active {
  scale: 1.1;
  z-index: -2;
  transition: all 0.3s;
}

/* social nav bar */
.social-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  gap: 1.2rem;

  top: 50%;
  right: 2.4rem;
  background-color: rgb(255, 255, 255, 0.95);

  box-shadow: 0.1rem 0 1rem #000;

  border-radius: 2rem;
  padding: 2.4rem;

  height: 16rem;
  z-index: 999;
  transition: all 0.3s;
}
/* /////////////////////// */
/* Biography Section */
/* ////////////////////// */

.biography {
  padding: 4.8rem;
  row-gap: 2.4rem;
  /* background-color: orangered; */
}

.biography-text-container {
  display: flex;
  gap: 2.2rem;
}

.biography-text {
  align-self: center;
  justify-content: center;
  font-size: 2rem;
  /* padding: 3.2rem 2.4rem; */
  padding: 2.4rem;
  line-height: 1.3;
  color: #1a1a1a;
}

.biography-image-container {
  /* max-width: 36rem; */
  max-width: 120rem;
  background-color: #000;
  justify-self: center;
  align-self: center;
  position: relative;
  background-color: #fff;
}

.biography-image {
  align-self: center;
  justify-content: center;
  max-width: 100%;
  border-radius: 0.5rem;
}

/* /////////////////////// */
/* Gallery Section */
/* ////////////////////// */

.gallery-main-container {
  display: grid;
  row-gap: 9.6rem;

  padding-bottom: 4.8rem;
}

.gallery-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 4.4rem;
  margin: 0 auto;
  font-weight: 600;
  letter-spacing: 0.6rem;
  text-transform: uppercase;
  padding: 5rem;
  font-family: "Cormorant Garamond", serif;
}

.gallery-image-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  column-gap: 3.2rem;
  row-gap: 3.2rem;
  overflow: hidden;
  margin: 0 auto;
}

.gallery-image {
  max-width: 100%;
  transition: all 0.3s;
}

.gallery-image:hover {
  scale: 1.1;
  cursor: pointer;
}

.image-holder {
  overflow: hidden;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Gallery Button */
.btn-gallery,
.btn-gallery:link,
.btn-gallery:visited {
  display: inline-block;

  grid-column: 2;
  justify-self: center;

  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  background-color: #000;
  text-align: center;
  /* text-transform: uppercase; */

  padding: 1.8rem 3.6rem;
  border-radius: 0.8rem;
  transition: all 0.3s;
  cursor: pointer;
  border: solid #fff 0.1rem;
}

.btn-gallery:hover,
.btn-gallery:active {
  color: #000;
  background-color: #fff;
}

/* /////////////////////// */
/* Price Section */
/* ////////////////////// */

.section-price {
  row-gap: 4.8rem;
}

.header-container-price {
  display: inline-block;
  padding: 4.8rem;
  margin: 0 auto;
  /* max-width: 180rem; */
}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-self: center;
  column-gap: 4.8rem;
}

.card-holder {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 48rem;
  box-shadow: 0 0.1rem 1rem #333;

  padding: 2.4rem;
  border-top-left-radius: 4.8rem;
  border-bottom-right-radius: 4.8rem;
  position: relative;
  overflow: hidden;
}

.ribbon {
  width: 24rem;
  max-height: 0.8rem;
  padding: 1.6rem 2.4rem;
  background-color: gold;

  font-size: 1.2rem;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 5%;
  right: -25%;

  transform: rotate(45deg);
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  row-gap: 1.2rem;
  color: #000;
}

.price {
  font-size: 2.4rem;
}

.price-pence {
  color: #333;
}

.price-symbol {
  font-size: 8rem;
}

.card-title {
  font-size: 3.2rem;
}

.description-holder {
  font-size: 1.8rem;
  list-style: none;
  line-height: 1.5;
}

.description-icon {
  font-size: 1.6rem;
  color: green;
  padding-right: 1rem;
}

/* Testimonials */

.slider {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80rem;
  height: 24rem;
  padding: 4.8rem;
  line-height: 3.2rem;

  border-radius: 2rem;
  /* box-shadow: 0 0.1rem 1rem #333; */
  position: relative;
}

.slide {
  display: flex;
  flex-direction: column;

  opacity: 0;
  padding: 2.4rem 3.2rem;
  position: absolute;
}

.slide-header {
  font-size: 3rem;
  padding-bottom: 1.6rem;
}

.slide-comment {
  padding: 1.6rem 0;
  line-height: 3.2rem;
  font-size: 2rem;
  color: #1a1a1a;
}

.quotes {
  color: gold;
  font-size: 5.2rem;
}

.author {
  font-size: 1.8rem;
  text-align: end;
  color: #1a1a1a;
}

/* slider testimonial buttons */

.slider-button,
.slider-button:link,
.slider-button:visited {
  background-color: #fff;
  border: none;
  cursor: pointer;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  box-shadow: 0 0.1rem 1.2rem #333;

  position: absolute;
  transition: all 0.3s;
}

.slider-button:hover,
.slider-button:active {
  background-color: #000;
  color: #fff;
}

.slider-testimonial-icon {
  font-size: 4.8rem;
}

.slide--active {
  opacity: 1;
}

.button--next {
  right: 0;
  top: 50%;
  transform: translateX(100%);
  /* transform: translateY(50%); */
}

.button--prev {
  left: 0;
  top: 50%;
  transform: translateX(-100%);
}

/* /////////////////////// */
/* CTA Section */
/* ////////////////////// */

/* .cta-section {
 */

.cta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;

  max-width: 144rem;
  padding: 4.8rem;
}

.cta-header {
  line-height: 1.8;
  text-align: center;
  font-size: 5.2rem;
  color: #fff;

  padding: 4.8rem;
}

.cta-button,
.cta-button:link,
.cta-button:visited {
  text-decoration: none;
  border: none;
  padding: 3.2rem 6.4rem;
  font-size: 2.4rem;
  border-radius: 2.4rem;

  background-color: #fff;
  color: #000;
  font-weight: 600;

  cursor: pointer;
  transition: all 0.3s;
}

.cta-button:hover,
.cta-button:active {
  background-color: #000;
  color: #fff;
  border-inline: solid 0.2rem #fff;
}

.cta-social-container {
  width: 80rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  list-style: none;
}

.cta-social-link,
.cta-social-link:link,
.cta-social-link:visited {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  color: #000;
  border-radius: 50%;
  text-align: center;
  font-size: 4.8rem;
  background-color: #ffffff;

  transition: all 0.4s;
}

.cta-social-link:hover,
.cta-social-link:active {
  scale: 1.5;
  background-color: #000;
  color: gold;
}

/* /////////////////////// */
/* Footer Section*/
/* ////////////////////// */

.footer-section {
  border-top: solid 0.1rem #fff;
}

.footer-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e6e6e6;
  padding: 4.8rem;
}

.copyright {
  text-align: center;
  font-size: 1.6rem;
}

/* Modal View */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
}

.modal img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 4.8rem;
  max-height: 100vh;
}

.close-modal {
  color: #fff;
  font-size: 4.8rem;
  position: absolute;
  top: 1%;
  right: 1%;
  cursor: pointer;
}
